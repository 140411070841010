<template>
  <div>
    <add-waste-offer>2222</add-waste-offer>
  </div>
</template>

<script>
import addWasteOffer from "@/components/ecologist/wasteExchange/addWasteOffer";

export default {
  name: "addWasteOfferView",
  components: { addWasteOffer },
};
</script>

<style scoped></style>
